/**
 * @graphql cc
 */
import gql from 'graphql-tag';
import { useLazyQuery, useQuery } from 'react-apollo';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import type {
	useEmbeddedMigrationQueryEmbeddedWithMigrationQuery as useEmbeddedMigrationQueryEmbeddedWithMigrationQuery$data,
	useEmbeddedMigrationQueryEmbeddedWithMigrationQueryVariables as useEmbeddedMigrationQueryEmbeddedWithMigrationQuery$variables,
} from './__types__/useEmbeddedMigrationQueryEmbeddedWithMigrationQuery';

let onMigrationSuccess: (data?: any) => void = () => {};
let onMigrationFailure: (err?: any) => void = () => {};

export const useEmbeddedMigrationQuery = (contentId: string, skipOnLoadQuery: boolean) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const trackMigrationEvent = ({
		action,
		actionSubjectId,
		withMigration,
		error,
	}: {
		action: 'completed' | 'failed';
		actionSubjectId: 'ncsMigrationOnLoad' | 'ncsMigrationOnEditClick';
		withMigration?: boolean;
		error?: string;
	}) => {
		return createAnalyticsEvent({
			type: 'sendOperationalEvent',
			data: {
				action,
				actionSubject: 'ncsMigrationQuery',
				actionSubjectId,
				source: 'embeddedPageViewScreen',
				object: {
					id: contentId,
					type: 'page',
				},
				attributes: {
					withMigration,
					error,
				},
			},
		}).fire();
	};

	const epMigrationOnLoad = useQuery<
		useEmbeddedMigrationQueryEmbeddedWithMigrationQuery$data,
		useEmbeddedMigrationQueryEmbeddedWithMigrationQuery$variables
	>(
		gql`
			query useEmbeddedMigrationQueryEmbeddedWithMigrationQuery($contentId: ID!) {
				content(id: $contentId) {
					nodes {
						id
						type
						metadata {
							frontend {
								embeddedWithMigration
							}
						}
					}
				}
			}
		`,
		{
			variables: {
				contentId,
			},
			fetchPolicy: 'network-only',
			skip: skipOnLoadQuery,
			onCompleted: (data) => {
				if (data?.content?.nodes?.[0]?.metadata?.frontend?.embeddedWithMigration ?? false) {
					trackMigrationEvent({
						action: 'completed',
						actionSubjectId: 'ncsMigrationOnLoad',
						withMigration: true,
					});
				} else {
					trackMigrationEvent({
						action: 'completed',
						actionSubjectId: 'ncsMigrationOnLoad',
						withMigration: false,
					});
				}
			},
			onError: (err) => {
				trackMigrationEvent({
					action: 'failed',
					actionSubjectId: 'ncsMigrationOnLoad',
					error: err.message,
				});
			},
		},
	);
	const [migrateData, { data: onClickData, loading: onClickLoading, error: onClickError }] =
		useLazyQuery<
			useEmbeddedMigrationQueryEmbeddedWithMigrationQuery$data,
			useEmbeddedMigrationQueryEmbeddedWithMigrationQuery$variables
		>(
			gql`
				query useEmbeddedMigrationQueryEmbeddedWithMigrationQuery($contentId: ID!) {
					content(id: $contentId) {
						nodes {
							id
							type
							metadata {
								frontend {
									embeddedWithMigration
								}
							}
						}
					}
				}
			`,
			{
				variables: {
					contentId,
				},
				fetchPolicy: 'network-only',
				onCompleted: (data) => {
					if (data?.content?.nodes?.[0]?.metadata?.frontend?.embeddedWithMigration ?? false) {
						trackMigrationEvent({
							action: 'completed',
							actionSubjectId: 'ncsMigrationOnEditClick',
							withMigration: true,
						});
						onMigrationSuccess(onClickData);
					} else {
						trackMigrationEvent({
							action: 'completed',
							actionSubjectId: 'ncsMigrationOnEditClick',
							withMigration: false,
						});
						onMigrationFailure();
					}
				},
				onError: (err) => {
					trackMigrationEvent({
						action: 'failed',
						actionSubjectId: 'ncsMigrationOnEditClick',
						error: err?.message,
					});
					onMigrationFailure(err);
				},
			},
		);

	const migrateEPData = (
		onSuccess: (data?: any) => void = () => {},
		onFailure: (err?: any) => void = () => {},
	) => {
		onMigrationSuccess = onSuccess;
		onMigrationFailure = onFailure;
		migrateData();
	};

	return {
		epMigrationOnLoad,
		epMigrationOnClick: {
			migrateEPData,
			data: onClickData,
			loading: onClickLoading,
			error: onClickError,
		},
	};
};
