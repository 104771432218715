/**
 * @graphql cc
 */
import { useQuery } from 'react-apollo';
import { useMemo, useCallback, useEffect } from 'react';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { fg } from '@confluence/feature-gating';

import type {
	FabricEditorEligibilityQueryVariables,
	FabricEditorEligibilityQuery as FabricEditorEligibilityQueryType,
} from './__types__/FabricEditorEligibilityQuery';
import { FabricEditorEligibilityQuery } from './FabricEditorEligibilityQuery.graphql';

export const useFabricEligibilityMigrationQuery = ({
	contentId,
	isFabricSupported,
}: {
	contentId: string;
	isFabricSupported?: boolean;
}) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const shouldFetch = isFabricSupported === false && fg('platform_editor_on_edit_convert_fg');
	const { data, error, loading } = useQuery<
		FabricEditorEligibilityQueryType,
		FabricEditorEligibilityQueryVariables
	>(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Preloaded
		FabricEditorEligibilityQuery,
		{
			fetchPolicy: 'cache-and-network',
			variables: { contentId },
		},
	);

	const fireErrorEvent = useCallback(
		({ reason }: { reason: 'PROMISE_REJECTED' | 'APOLLO_ERROR' }) => {
			createAnalyticsEvent({
				type: 'sendOperationalEvent',
				data: {
					action: 'failed',
					actionSubject: 'fabricEditorEligibilityQuery',
					attributes: {
						contentId,
						reason,
					},
					source: 'editContentButton',
				},
			}).fire();
		},
		[contentId, createAnalyticsEvent],
	);

	useEffect(() => {
		if (error) {
			fireErrorEvent({ reason: 'APOLLO_ERROR' });
		}
	}, [error, fireErrorEvent]);

	return useMemo(() => {
		if (!shouldFetch) {
			return {
				fabricEditorEligibility: null,
				contentHash: null,
				isFabricEligibilityLoading: false,
			};
		}

		if (!data && shouldFetch) {
			return {
				isFabricEligibilityLoading: true,
				fabricEditorEligibility: null,
				contentHash: null,
			};
		}

		if (!data && loading) {
			return {
				isFabricEligibilityLoading: true,
				fabricEditorEligibility: null,
				contentHash: null,
			};
		}

		if (data) {
			const metadataFrontend = data?.content?.nodes?.[0]?.metadata?.frontend;
			const fabricEditorEligibility = metadataFrontend?.fabricEditorEligibility;
			const contentHash = metadataFrontend?.contentHash;
			return {
				fabricEditorEligibility,
				contentHash,
				isFabricEligibilityLoading: false,
			};
		}
		return {
			fabricEditorEligibility: null,
			contentHash: null,
			isFabricEligibilityLoading: false,
		};
	}, [data, loading, shouldFetch]);
};
